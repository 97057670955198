<template>
  <b-card body-class="p-1">
    <b-custom-tabs :tabs="tabs" lazy-once />
  </b-card>
</template>

<script>
import detectionAndSuggestions from "./detectionAndSuggestion/list.vue";
import observation from "./observations/list.vue";
import incidents from "./incidents/incidentList.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Tespit / Öneri Defteri", component: detectionAndSuggestions },
        { title: "Gözlemler", component: observation },
        { title: "Olaylar", component: incidents },
        { title: "Toplantılar" },
        { title: "Ekipmanlar" },
      ],
    };
  },
};
</script>

<style></style>
